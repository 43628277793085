const PoliticasDevolucion = () => {
  return (
    <div>
      <p>
        Intentamos que todo salga como lo pediste, en caso de faltantes de algún
        producto por falta de Stock te reintegraremos el valor de los mismos.
        Nos escribís al whatssap o enviás un mail a{" "}
        <strong>devolucion@vetustus.com.ar</strong>
      </p>
      <p>
        Si precisás realizar un cambio tenés un plazo máximo de 3 días corridos
        de recibida la compra para efectuar el mismo (sujeto a disponibilidad).
        Los productos deberán encontrarse en el mismo estado en el que fueron
        enviados o recibidos. Coordinaremos con vos para que tu experiencia de
        compra sea significativa.
      </p>
    </div>
  );
};

export default PoliticasDevolucion;
