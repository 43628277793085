import { useEffect, useState } from "react";
import Routing from "routing/index";
import Logo from "assets/images/logo.png";

const AppContainer = ({ authStatusCheck }) => {
  const [showPopup, setShowPopup] = useState(!localStorage.getItem("anypop"));

  useEffect(() => {
    if (localStorage.getItem("anypop")) return;
    setTimeout(() => {
      setShowPopup(false);
      localStorage.setItem("anypop", "true");
    }, 2000);
  }, []);

  useEffect(() => {
    authStatusCheck();
  }, [authStatusCheck]);

  /*const AppContainer = ({ authCheckDone, authStatusCheck }) => {
  const [fakeLoader, setFakeLoader] = useState(0);*/
  /* const [dev, setDev] = useState(!!localStorage.getItem('dev') || false); */

  /*useEffect(() => {
    authStatusCheck();
  }, [authStatusCheck]);

  useEffect(() => {
    let i = 0;

    const loop = () => {
      if (Math.random() < 0.23) {
        const val = Math.random() * 8;
        i += val;
        setFakeLoader(i);
      }
      if (i > 100) return;
      setTimeout(loop, 1000 / 60);
    };

    loop();
  }, []);*/

  /* useEffect(() => {
    const pass = [77, 79, 78, 67, 72];
    let actual = [];

    const kd = (e) => {
      let step = actual.length;

      if (e.which === pass[step]) {
        actual.push(e.which);
      } else {
        actual = [];
      }

      if (actual.toString() === pass.toString()) {
        setDev(true);
        localStorage.setItem('dev', '1');
      }
    };

    window.addEventListener('keydown', kd);

    return () => window.removeEventListener('keydown', kd);
  }, []); */

  /* if (!authCheckDone)
    return (
      <div className="loading-main-container">
        <div
          className="loading-main-container-filler"
          style={{ width: `${fakeLoader}%` }}
        ></div>
        <i className="fas fa-spinner fa-pulse"></i>
        <span></span>
      </div>
    );*/

  /* if (!dev) {
    return (
      <div className='dev-panel'>
        <div>
          <img src={Logo} alt='Logo Vetustus' />
          <p>
            El sitio se encuentra en mantenimiento, pronto estará disponible
            nuevamente.
          </p>
        </div>
      </div>
    );
  } */

  return (
    <>
      <div className={`popup ${showPopup ? "popup-visible" : ""} `}></div>
      <Routing />
    </>
  );
};

export default AppContainer;
