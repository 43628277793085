const ComprasComunitarias = () => {
  return (
    <p>
      Si elegís compras en grupo accedes a un descuento especial (es
      imprescindible que te registres). Enganchate con un familiar, amigxs o tu
      chongo. Ahorrás y te alimentás mejor!
    </p>
  );
};

export default ComprasComunitarias;
