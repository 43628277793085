const PuntoRetiro = () => {
  return (
    <p>
      Nuestro punto de Retiro es en casa de Any! (San Antonio de Padua) Vamos a
      coordinar con vos el encuentro.
    </p>
  );
};

export default PuntoRetiro;
