import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import nosotros1 from "assets/images/nosotros.jpeg";
import nosotros2 from "assets/images/nosotros1.jpeg";

import { useTitle } from "react-use";

const Nosotros = () => {
  useTitle(`Nosotros | ${process.env.REACT_APP_PAGE_TITLE}`);

  return (
    <>
      <PageLayout>
        <Header />
        <main class="main-about">
          <div className="wrapper">
            <div className="cols">
              <div className="col col-1">
                <div>
                  <div className="head">
                    <h1>Quienes somos</h1>
                  </div>
                  <p>
                    Somos amigos que buscando un camino que nos mejore como
                    Seres en todo sentido buscamos los beneficios de una
                    alimentación que cuida la microbiota intestinal,nuestra
                    salud física, mental y emocional. Nos descubrimos para
                    acompañarnxs en el camino propio con información y productos
                    más nobles.
                  </p>
                  <p className="we-text">Vamos en esa ruta.</p>

                  <p className="p-spec">Vetustus.</p>
                </div>
              </div>
              <div className="we-pictures">
                <img src={nosotros1}></img>
                <img src={nosotros2}></img>
                <img src={nosotros1}></img>
              </div>
            </div>
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Nosotros;
